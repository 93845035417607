<template>
  <li v-if="item.schema !== 'Folder'">
    <div :class="'sharedHover ' + isCurrentPage">
      <a 
        :href="href" 
        :title="item.title"
        :target="item.target"
        :class="hasChildren ? 'hasChildren' : '' "
      >
        {{ item.name }}
      </a>
      <button 
        v-if="hasChildren"
        :id="toggleID" 
        :aria-haspopup="true" 
        :aria-expanded="toggleOpen"
        @click="toggleOpen = toggleOpen ? false : true"
      >
        <span class="sr-only">
          Toggle children of {{ item.title }}
        </span>
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-down" class="svg-inline--fa fa-chevron-down fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
          <path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
        </svg>
      </button>
    </div>
    <ul 
      v-if="hasChildren" 
      :class="toggleOpen ? 'active' : ''" 
      :aria-labelledby="toggleID"
    >
      <NavItem 
        v-for="(child, i) in children" 
        :key="ariaID + '_' + i" 
        :nav="child" 
        :ariaID="ariaID + '_' + i" 
        :currentPageXID="currentPageXID"
        :serverPath="serverPath"
        @openParent="openParent"
      />
    </ul>
  </li>
</template>

<script>
  export default {
    name: 'NavItem',
    props: {
      nav: Object,
      ariaID: [ Number, String ],
      currentPageXID: String,
      serverPath: String
    },
    data() {
      return {
        toggleOpen: false,
      }
    },
    computed: {
      item() { return this.nav.attributes },
      isVirtualLink() { return this.item.schema === 'VirtualLinkComponent' },
      isExternalLink() { return this.isVirtualLink && this.item.virtualLink.includes('://')  },
      href() { 
          let link = (this.isVirtualLink) ? this.item.virtualLink : this.item.url

          if (this.serverPath.includes('dsspreview')) link = this.item.id + '.xml'

          return (this.isExternalLink) ? link : this.serverPath + link
      },
      children() { return this.nav.children.filter(x => x.attributes.schema !== 'Folder') },
      toggleID() { return 'nav-l-tog_' + this.ariaID },
      hasChildren() { return this.children.length > 0 },
      isCurrentPage() { return (this.item.id == this.currentPageXID) ? 'active' : '' }
    },
    created() {
      if (this.item.id === this.currentPageXID) this.openParent()
    },
    methods: {
      openParent() {
        this.toggleOpen = true
        this.$emit('openParent')
      }
    },
  }
</script>