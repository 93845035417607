<template>
  <ul id="left-nav" class="hidden-xs hidden-sm" v-if="nav.length > 0">
    <NavItem 
      v-for="(child, i) in nav" 
      :key="i" 
      :nav="child" 
      :ariaID="i" 
      :currentPageXID="currentPageXID"
      :serverPath="serverPath"
    />
  </ul>
</template>

<script>
  /*
    This component grabs the Top Navigation component JSON from the DSSAPI.
    Then it finds the Left Nav from within Top Navigation object and renders it
  */

  import NavItem from '@/components/navItem/NavItem.vue'

  export default {
    name: 'LeftNav',
    data() {
      return {
        navXID: '',
        subTreeXID: '',
        currentPageXID: '',
        rawNav: [],
        serverPath: '', 
      }
    },
    computed: {
      nav() {
        return this.filterNav(this.rawNav)
      }
    },
    components: {
      NavItem,
    },
    created() {
      this.getServerPath()
      this.getXIDs()
      this.getNav()
      this.filterNav(this.rawNav)
    },
    updated() {
      this.$nextTick(function () {
        this.leftNavHotfix();
      });
    },
    methods: {
      filterNav(nav) {
        nav.forEach( (item, i) => {
          // Recurse through children
          if (item.children) this.filterNav(item.children)

          /* 
            Navigation components in IGX typically won't traverse 
            into traditional folders---leaving missing children
            A "Navigation Folder" component was created to remedy this.
            However, Navigation Folder components inside DSSAPI responses create 
            double nesting with links pointing to the folder component's xID.
            The code below removes the double nesting and the undesired link.
            Basically... it replaces the item (Navigation Folder) with it's children
          */
          if (item.attributes.schema == 'NavigationFolder') nav.splice(i, 1, ...item.children)
        })

        return nav
      },
      getServerPath() {
        const base = document.querySelector('base'),
              location = window.location.href.toLowerCase()

        this.serverPath = (base && base.href) ? base.href.toLowerCase() : '/'

        // DSS Preview Variants
        if (location.includes('/dsspreview')) { 
          // console.log('dsspreview')

          // // if pub targets ever matter
          // let queryString = window.location.search,
          //     urlParams = new URLSearchParams(queryString),
          //     target = urlParams.get('pubTarget')

          // console.log('Left Navi: Hey listen!')
          // console.log(target)

          this.serverPath = (location.includes('seattlev10')) ? '/seattle'
                          : (location.includes('.seattle.gov')) ? 'https://wwwqa.seattle.gov'
                          : '/'

          // console.log(this.serverPath)
        }
      },
      getXIDs() {
        this.navXID = window.topNavXID || '' /* The xID of the page's Top Navigation CMS Component  */
        this.subTreeXID = window.leftNavTreeXID || '' /* The xID starting point for the Left Nav  */
        this.currentPageXID = window.pageXID || '' /* The xID of the page  */
      },
      async getNav() {
        // Method issues an XHR to grab the Top Navigation Component from the DSSAPI.

        try {
          const api = `${ this.serverPath }/api/content/${ this.navXID }/elements/navigation`,
                response = await fetch(api, { cache: 'force-cache' }),
                data = await response.json()



          // console.log('fetch response: ', response)
          // console.log('fetch data: ', data)

          const navTree = data.elements[0].children

          console.log('left nav: ', navTree)

          //  Is Left Nav the Main Navigation of the page or it is Secondary to a Top Nav?
          if (this.subTreeXID == 'noSubTree') {
            // Left Nav is the Main Nav Tree
            this.rawNav = navTree
          } else {
            // Left Nav is a subtree of the Main Nav Tree
            this.findLeftNav(navTree, this.subTreeXID)
          }

        } catch (err) {
          console.log('Left Nav: No Top Navigation?') /* eslint-disable-line */
          this.leftNavHotfix();
        }
      },
      findLeftNav(nav, xID) {
        // Method recurses through a nested navigation object to find the Left Nav tree 

        for (const item of nav) {
          if (item.attributes.id === xID) {
            this.rawNav = item.children
            break
          } else {
            this.findLeftNav(item.children, xID)
          }
        }
      },
      leftNavHotfix() {
          // ADA Hotfix for empty nav containers
          var aside = document.querySelector('aside[aria-labelledby="leftCol"]');

          if (aside) {
            // console.log(aside)
            var noLists = true;

            for (var list of aside.querySelectorAll("ul")) {
              if (list.querySelectorAll('li, a').length > 0) noLists = false;
              // console.log(list, children.length)
            }

            if (noLists) {
              aside.remove();
              window.zz_emptyLeftColRemoved = true;
              // console.log('empty left col removed');
            }
          }
      }
    }
  }
</script>

<style lang="scss">
  #left-nav {
    margin-right: 40px;
    padding: 0;

    ul {
      padding-left: 10px;
      display: none;

      &.active {
        display: block;
      }
    }
    li {
      list-style: none;
      position: relative;
    }
    a {
      color: #333;
      display: flex;
      width: 100%;
      padding: 10px;
      font-size: 14px;
      font-weight: normal;
      line-height: 15px;

      &:hover {
        text-decoration: underline;
      }

      &.hasChildren {
        padding-right: 30px;
      }
    }
    button {
      position: absolute;
      top: 0px;
      right: 0px;
      display: flex;
      width: 32px;
      height: 100%;
      justify-content: center;
      align-items: center;
      padding: 0px;
      background: transparent;
      border: 0px;

      svg {
        width: 12.25px;
        color: #003da5;
        transition: all 500ms;
      }
      &[aria-expanded="true"] svg {
        transform: rotate(180deg);
      }
    }
    .sharedHover {
      // display: flex;
      position: relative;

      &:hover,
      &.active {
        background: #f1f1f1;
      }
      &.active a {
        font-weight: bold;
      }
    }

    /* Top Level Items */
    > li {
      border-top:1px solid #ccc;

      > a {
        font-size: 16px;
        line-height: 17px;
      }
    }
  }
</style>
